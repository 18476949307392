export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Search',
        to: '/search',
        icon: 'cil-search'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Create New Invoice',
        to: '/createNewInvoice',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Data Maintenance']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Company Info',
        to: '/companyInfo',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Customer',
        to: '/customer',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Currency',
        to: '/currency',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Payment Item',
        to: '/payitem',
        icon: 'cil-cursor'
      },
         
    
    ]
  }
]