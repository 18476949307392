<template>
  <CHeader fixed with-subheader light>
    
   <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
       <CHeaderNav class="d-md-down-none mr-auto">
       </CHeaderNav>
    <CHeaderNav class="mr-4">
       <TherHeaderDropdownAccnt/>
    </CHeaderNav>
 
  </CHeader>

</template>

<script>
import TherHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue';
export default {
  components:{
    TherHeaderDropdownAccnt
  }
}
</script>
